// extracted by mini-css-extract-plugin
export var aboutYouStyles = "about-you-module--aboutYouStyles--1o2+Q";
export var dlAboutYouStyles = "about-you-module--dlAboutYouStyles--AncPT";
export var firstTermStyles = "about-you-module--firstTermStyles--BAale";
export var secondTermStyles = "about-you-module--secondTermStyles--yHKhF";
export var thirdTermStyles = "about-you-module--thirdTermStyles--6LZT8";
export var fourthTermStyles = "about-you-module--fourthTermStyles--aHmyX";
export var definitionStyles = "about-you-module--definitionStyles--W0wFd";
export var hoverBg = "about-you-module--hoverBg--0xXab";
export var definition1Styles = "about-you-module--definition1Styles--UQKFv";
export var definition2Styles = "about-you-module--definition2Styles--gIEH0";
export var definition3Styles = "about-you-module--definition3Styles--C-Oz0";
export var definition4Styles = "about-you-module--definition4Styles--hHUKT";