import React, { useLayoutEffect, useRef } from "react";
import VisionRotationMesh from "../reusable/VisionRotationMesh";
import { Canvas } from "@react-three/fiber";
import * as THREE from "three";

import gsap from "gsap";

import {
  h2InnerStyles,
  rightAlignStyles,
  technoOrange,
  offsetRightStyles,
  pStyles,
} from "../styles/global.module.css";
import {
  deepDiveStyles,
  globeStyles,
  handStyles,
} from "../styles/deep-dive.module.css";

function DeepDive({ getStandAloneTimelines }) {
  let particlePositions = new Float32Array(1500 * 3);
  const tlDeepDive = useRef();
  tlDeepDive.current = gsap.timeline();

  useLayoutEffect(() => {
    let parentElement = document.getElementById("deep-dive");
    let children = parentElement.childNodes;
    tlDeepDive.current.from(children, {
      duration: 1,
      opacity: 0,
      stagger: { each: 0.2 },
    });
    getStandAloneTimelines(tlDeepDive.current, "tl-deep-dive");

    return function cleanupListener() {
      try {
        tlDeepDive.current.kill();
      } catch (e) {
        // Code to run if an exception (error) occurs
      }
    };
  }, []);

  useLayoutEffect(() => {
    for (let i = 0; i < 1500; i++) {
      let theta = THREE.Math.randFloatSpread(360);
      let phi = THREE.Math.randFloatSpread(360);

      const x = 200 * Math.sin(theta) * Math.cos(phi);
      const y = 200 * Math.sin(theta) * Math.sin(phi);
      const z = 200 * Math.cos(theta);

      particlePositions[i * 3] = x;
      particlePositions[i * 3 + 1] = y;
      particlePositions[i * 3 + 2] = z;
    }
  }, []);
  return (
    <section id="deep-dive" className={`section ${deepDiveStyles}`}>
      <h2 className={`${h2InnerStyles} ${rightAlignStyles}`}>
        Two-Week{" "}
        <span className={`${technoOrange} ${offsetRightStyles}`}>
          Deep Dive
        </span>
      </h2>
      <div id="globes" className={globeStyles}>
        <Canvas camera={{ position: [0, 0, 600], fov: 45, near: 1, far: 4000 }}>
          <ambientLight intensity={0.1} />
          <directionalLight position={[0, 0, 5]} />
          <VisionRotationMesh>
            <points>
              <bufferGeometry attach="geometry">
                <bufferAttribute
                  attachObject={["attributes", "position"]}
                  array={particlePositions}
                  itemSize={3}
                  count={particlePositions.length / 3}
                  setUsage={THREE.DynamicDrawUsage}
                />
              </bufferGeometry>
              <pointsMaterial
                attach="material"
                color="#fc4d0d"
                size="5"
                transparent="true"
                sizeAttenuation="false"
              ></pointsMaterial>
            </points>
          </VisionRotationMesh>
        </Canvas>
      </div>
      <figure id="hand" className={handStyles}>
        <img src="/vision-hand.svg" alt="Vision Hand" />
      </figure>
      <p className={pStyles}>
        An extremely important part of our process is our two-week Deep Dive.
        Expect to be asked a lot of questions from our team during this period.
        Our researchers immerse themselves while trying to understand the
        challenges of your industry and consume whatever knowledge they can
        garner on your specific business model, location, cultural proximity
        etc. Then in collaboration with our digital experts we begin developing
        long-term and short-term digital strategies.
      </p>
    </section>
  );
}

export default DeepDive;
