import React, { useLayoutEffect, useRef } from "react";
import gsap from "gsap";
import {
  technoOrange,
  h2InnerStyles,
  centerAlignStyles,
  pStyles,
} from "../styles/global.module.css";

const contentFirstStyles = {
  gridArea: "6/2/7/4",
  height: "auto",
};

function ContentFirst({ getStandAloneTimelines }) {
  const tlContentFirst = useRef();
  tlContentFirst.current = gsap.timeline();

  useLayoutEffect(() => {
    let parentElement = document.getElementById("content-first");
    let children = parentElement.childNodes;
    tlContentFirst.current.from(children, {
      duration: 1,
      opacity: 0,
      stagger: { each: 0.2 },
    });
    getStandAloneTimelines(tlContentFirst.current, "tl-content-first");
    return function cleanupListener() {
      try {
        tlContentFirst.current.kill();
      } catch (e) {
        // Code to run if an exception (error) occurs
      }
    };
  }, []);
  return (
    <section
      id="content-first"
      className={`section`}
      style={contentFirstStyles}
    >
      <h2 className={`${h2InnerStyles} ${centerAlignStyles}`}>
        Content-First <span className={`${technoOrange}`}>Approach</span>
      </h2>
      <p className={pStyles}>
        Content is the backbone of the Digital Age and we put immense focus on
        the content that will eventually be the substance of our builds. Your
        content isn't always ready for the digital world, sometimes it may be
        too verbose or maybe you don't have it yet. We assist you with shaping
        your content based on your audience(s) to ensure your digital presence
        is both intentional and effective. With our approach, Content
        Development leads both our Design and Code Development. Your final
        product is highly customized to the content that you disseminate,
        creating an experience that feels well thought out and has a logical
        flow.
      </p>
    </section>
  );
}

export default ContentFirst;
