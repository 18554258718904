// extracted by mini-css-extract-plugin
export var strategyArtifactsStyles = "strategy-artifacts-module--strategyArtifactsStyles--UHbU+";
export var dlStrategyArtifactsStyles = "strategy-artifacts-module--dlStrategyArtifactsStyles--FqTzj";
export var svgNumberStyles = "strategy-artifacts-module--svgNumberStyles--NZDrX";
export var dtStrategyArtifactsStyles = "strategy-artifacts-module--dtStrategyArtifactsStyles--cfJ+A";
export var ddStrategyArtifactsStyles = "strategy-artifacts-module--ddStrategyArtifactsStyles--0m3b6";
export var firstTermStyles = "strategy-artifacts-module--firstTermStyles--BnoUE";
export var secondTermStyles = "strategy-artifacts-module--secondTermStyles--4ql-7";
export var pStrategyArtifactsStyles = "strategy-artifacts-module--pStrategyArtifactsStyles--sARGe";
export var leftArrowStyles = "strategy-artifacts-module--leftArrowStyles--mO0LO";
export var rightArrowStyles = "strategy-artifacts-module--rightArrowStyles--8Uvck";