import React, { useLayoutEffect, useRef, useState } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

import { Canvas } from "@react-three/fiber";

import {
  h2InnerStyles,
  h3InnerStyles,
  offsetLeftStyles,
  technoOrange,
  pStyles,
} from "../styles/global.module.css";
import {
  designArtifactsStyles,
  panelStyles,
  pagerStyles,
  randomObjectsStyles,
  prototypesStyles,
} from "../styles/design-artifacts.module.css";

import VisionRandomMesh from "../reusable/VisionRandomMesh";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

function DesignArtifacts({ getStandAloneTimelines }) {
  const tlDesignArtifacts = useRef();
  tlDesignArtifacts.current = gsap.timeline();
  const [activePager, setActivePager] = useState("first-slide");
  let offset = [];
  const clicked = useRef();
  let winScroll;
  let slide;

  useLayoutEffect(() => {
    let parentElement = document.getElementById("design-artifacts");
    let children = parentElement.childNodes;
    tlDesignArtifacts.current.from(children, {
      duration: 1,
      opacity: 0,
      stagger: { each: 0.2 },
    });
    getStandAloneTimelines(tlDesignArtifacts.current, "tl-design-artifacts");

    return function cleanupListener() {
      try {
        tlDesignArtifacts.current.kill();
      } catch (e) {
        // Code to run if an exception (error) occurs
      }
    };
  }, []);

  useLayoutEffect(() => {
    const panels = gsap.utils.toArray("#design-artifacts .panel");
    const panelList = document.getElementById("panel-list");
    panelList.addEventListener("scroll", changeSlide);

    panels.forEach((panel, i) => {
      offset[i] = panel.offsetLeft;
    });

    clicked.current = changeSlide;

    function changeSlide(event) {
      if (event.type === "scroll") {
        winScroll = event.target.scrollLeft;
        switch (true) {
          case winScroll < offset[1] / 2 && slide !== "first":
            slide = "first";
            setActivePager("first-slide");
            break;
          case winScroll > offset[1] / 2 &&
            winScroll < (offset[1] + offset[2]) / 2 &&
            slide !== "second":
            slide = "second";
            setActivePager("second-slide");
            break;
          case winScroll > (offset[1] + offset[2]) / 2 && slide !== "third":
            slide = "third";
            setActivePager("third-slide");
            break;
        }
      } else {
        try {
          switch (event) {
            case "first-slide":
              panelList.removeEventListener("scroll", changeSlide);
              gsap.to("#design-artifacts dl", {
                duration: 1,
                scrollLeft: offset[0],
              });
              setActivePager("first-slide");
              break;
            case "second-slide":
              panelList.removeEventListener("scroll", changeSlide);
              gsap.to("#design-artifacts dl", {
                duration: 1,
                scrollLeft: offset[1],
              });
              setActivePager("second-slide");
              break;
            case "third-slide":
              panelList.removeEventListener("scroll", changeSlide);
              gsap.to("#design-artifacts dl", {
                duration: 1,
                scrollLeft: offset[2],
              });
              setActivePager("third-slide");
              break;
          }
        } finally {
          panelList.addEventListener("scroll", changeSlide);
        }
      }
    }

    gsap.to(".active-pager", {
      strokeWidth: 7,
      strokeOpacity: 1,
    });
    gsap.to(".inactive-pager", {
      strokeWidth: 0,
      strokeOpacity: 0,
    });

    return () => panelList.removeEventListener("scroll", changeSlide);
  }, [activePager]);

  return (
    <section
      id="design-artifacts"
      className={`section ${designArtifactsStyles}`}
    >
      <h2 className={`${h2InnerStyles}`}>
        Design{" "}
        <span className={`${offsetLeftStyles} ${technoOrange}`}>Artifacts</span>
      </h2>
      <dl id="panel-list">
        <div className={`panel ${panelStyles}`}>
          <dt className={`${h3InnerStyles} ${technoOrange}`}>
            Low-Fidelity <br />
            Wireframes
          </dt>
          <dd>
            <p className={pStyles}>
              These are the building blocks of the user interfaces we eventually
              design. We call them "thoughts on paper". These aren't meant to
              showcase any design whatsoever but instead focuses our thoughts on
              functionality and logically creating the user's movement through
              the build.
            </p>
          </dd>
          <div className={randomObjectsStyles}>
            <Canvas
              camera={{ position: [0, 0, 100], fov: 45, near: 1, far: 4000 }}
            >
              <ambientLight intensity={0.2} />
              <directionalLight position={[0, 5, 5]} />
              <VisionRandomMesh rotation={0}>
                <meshPhongMaterial
                  attach="material"
                  color="#fc4d0d"
                  wireframe
                />
              </VisionRandomMesh>
            </Canvas>
          </div>
        </div>
        <div className={`panel ${panelStyles}`}>
          <dt className={`${h3InnerStyles} ${technoOrange}`}>
            High-Fidelity <br />
            Mockups
          </dt>
          <dd>
            <p className={pStyles}>
              These are a few notches up from wireframes. At this point, the
              look and feel is in full swing. This is what your final product
              will look like. Every detail of our designs can be traced back to
              the Roadmap Strategy artifact and our current knowledge of your
              target audience. Colors are selected based on color theory, shapes
              are selected to invoke a feel that is consistent with your
              messaging etc. We design every detail intentionally.
            </p>
          </dd>
          <div className={randomObjectsStyles}>
            <Canvas
              camera={{ position: [0, 0, 100], fov: 45, near: 1, far: 4000 }}
            >
              <ambientLight intensity={0.1} />
              <directionalLight position={[0, 3, 4]} />
              <VisionRandomMesh rotation={0}>
                <meshPhongMaterial attach="material" color="#fc4d0d" />
              </VisionRandomMesh>
            </Canvas>
          </div>
        </div>
        <div className={`panel ${panelStyles}`}>
          <dt className={`${h3InnerStyles} ${technoOrange}`}>
            Interactive <br />
            Prototypes
          </dt>
          <dd>
            <p className={pStyles}>
              This is the icing on the cake! Interactivity plays a massive role
              in how digital experiences are interpreted. They're the difference
              between a good UI and a great UI. To ensure that the feel of
              entire experience is intentional we add interactivity to your
              mockups so have a comprehensive understanding of the feel of the
              UI before any code is written.
            </p>
          </dd>
          <div className={`prototypes ${randomObjectsStyles}`}>
            <Canvas
              camera={{ position: [0, 0, 100], fov: 45, near: 1, far: 4000 }}
            >
              <ambientLight intensity={0.2} />
              <directionalLight position={[0, 5, 5]} />
              <VisionRandomMesh rotation={0.005}>
                <meshPhongMaterial attach="material" color="#fc4d0d" />
              </VisionRandomMesh>
            </Canvas>
          </div>
        </div>
      </dl>
      <nav id="pager" className={pagerStyles}>
        <button onClick={() => clicked.current("first-slide")}>
          <svg
            width="23"
            height="23"
            viewBox="0 0 23 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g>
              <circle cx="11.5664" cy="11.7815" r="7.5" fill="#FC4D0D" />
              <circle
                id="pager1"
                className={`${
                  activePager === "first-slide"
                    ? "active-pager"
                    : "inactive-pager"
                }`}
                cx="11.5664"
                cy="11.7815"
                r="7"
                stroke="white"
                strokeOpacity="0"
              />
            </g>
          </svg>
        </button>
        <button onClick={() => clicked.current("second-slide")}>
          <svg
            width="23"
            height="23"
            viewBox="0 0 23 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g>
              <circle cx="11.5664" cy="11.7815" r="7.5" fill="#FC4D0D" />
              <circle
                id="pager2"
                className={`${
                  activePager === "second-slide"
                    ? "active-pager"
                    : "inactive-pager"
                }`}
                cx="11.5664"
                cy="11.7815"
                r="7"
                stroke="white"
                strokeOpacity="0"
              />
            </g>
          </svg>
        </button>
        <button onClick={() => clicked.current("third-slide")}>
          <svg
            width="23"
            height="23"
            viewBox="0 0 23 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g>
              <circle cx="11.5664" cy="11.7815" r="7.5" fill="#FC4D0D" />
              <circle
                id="pager3"
                className={`${
                  activePager === "third-slide"
                    ? "active-pager"
                    : "inactive-pager"
                }`}
                cx="11.5664"
                cy="11.7815"
                r="7"
                stroke="white"
                strokeOpacity="0"
              />
            </g>
          </svg>
        </button>
      </nav>
    </section>
  );
}

export default DesignArtifacts;
