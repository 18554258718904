// React
import * as React from "react";
import { useRef, useLayoutEffect } from "react";

// CSS
import "normalize.css";
import styled from "styled-components";

// GSAP
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

// Meta Information
import SEO from "../components/reusable/Seo";
import { Helmet } from "react-helmet";

// Components: HTML Structure
import VisionMatrix from "../components/structure/VisionMatrix";
import Layout from "../components/structure/Layout";
import MainContent from "../components/structure/MainContent";
import Sidebar from "../components/structure/Sidebar";
import Branding from "../components/structure/Branding";
import Footer from "../components/structure/Footer";
import ScrollCue from "../components/reusable/ScrollCue";
import LetsBuild from "../components/reusable/LetsBuild";

// Components: Sections
import Header from "../components/structure/Header";
import AllAboutYou from "../components/design/AllAboutYou";
import DeepDive from "../components/design/DeepDive";
import StrategyArtifacts from "../components/design/StrategyArtifacts";
import ContentFirst from "../components/design/ContentFirst";
import DesignArtifacts from "../components/design/DesignArtifacts";

const Container = styled.div`
  display: grid;
  grid-template-areas: "vision";
`;

gsap.registerPlugin(ScrollTrigger);

const DesignPage = () => {
  const tlLetsBuild = useRef();
  const tlMatrix = useRef();
  const tlBranding = useRef();
  const tlSidebar = useRef();
  const tlSocialNav = useRef();
  const tlScrollDown = useRef();
  const tlAboutYou = useRef();
  const tlDeepDive = useRef();
  const tlDesignArtifacts = useRef();
  const tlStrategyArtifacts = useRef();
  const tlContentFirst = useRef();

  tlLetsBuild.current = gsap.timeline();
  tlMatrix.current = gsap.timeline();
  tlBranding.current = gsap.timeline();
  tlSidebar.current = gsap.timeline();
  tlSocialNav.current = gsap.timeline();
  tlScrollDown.current = gsap.timeline();
  tlAboutYou.current = gsap.timeline();
  tlDeepDive.current = gsap.timeline();
  tlDesignArtifacts.current = gsap.timeline();
  tlStrategyArtifacts.current = gsap.timeline();
  tlContentFirst.current = gsap.timeline();

  function getStandAloneTimelines(tl, name) {
    switch (name) {
      case "tl-matrix":
        tlMatrix.current.add(tl, name);
        break;
      case "tl-branding":
        tlBranding.current.add(tl, name + `+=1`);
        break;
      case "tl-sidebar":
        tlSidebar.current.add(tl, name);
        break;
      case "tl-social-nav":
        tlSocialNav.current.add(tl, name);
        break;
      case "tl-scroll":
        tlScrollDown.current.add(tl, name);
        break;
      case "tl-about-you":
        tlAboutYou.current.add(tl, name);
        break;
      case "tl-deep-dive":
        tlDeepDive.current.add(tl, name);
        break;
      case "tl-content-first":
        tlContentFirst.current.add(tl, name);
        break;
      case "tl-design-artifacts":
        tlDesignArtifacts.current.add(tl, name);
        break;
      case "tl-strategy-artifacts":
        tlStrategyArtifacts.current.add(tl, name);
        break;
      case "tl-lets-build":
        tlLetsBuild.current.add(tl, name);
        break;
    }
  }

  useLayoutEffect(() => {
    gsap.utils.toArray(".section").forEach((section, i) => {
      ScrollTrigger.create({
        trigger: section,
        start: "top 30%",
        toggleActions: "restart none none none",
        // markers: true,
        animation: tlMatrix.current,
      });
    });
    ScrollTrigger.create({
      trigger: "header",
      start: "top 25%",
      toggleActions: "play pause resume none",
      // markers: true,
      animation: tlScrollDown.current,
      end: "bottom 10%",
    });
    ScrollTrigger.create({
      trigger: "#about-you",
      start: "top 25%",
      // markers: true,
      animation: tlAboutYou.current,
    });
    ScrollTrigger.create({
      trigger: "#deep-dive",
      start: "top 25%",
      // markers: true,
      animation: tlDeepDive.current,
    });
    ScrollTrigger.create({
      trigger: "#strategy-artifacts",
      start: "top 25%",
      // markers: true,
      animation: tlStrategyArtifacts.current,
    });
    ScrollTrigger.create({
      trigger: "#content-first",
      start: "top 25%",
      // markers: true,
      animation: tlContentFirst.current,
    });
    ScrollTrigger.create({
      trigger: "#design-artifacts",
      start: "top 25%",
      // markers: true,
      animation: tlDesignArtifacts.current,
    });
    ScrollTrigger.create({
      trigger: "#lets-build",
      start: "top 25%",
      // markers: true,
      animation: tlLetsBuild.current,
    });

    return function cleanupListener() {
      try {
        tlLetsBuild.current.kill();
        tlMatrix.current.kill();
        tlBranding.current.kill();
        tlSidebar.current.kill();
        tlSocialNav.current.kill();
        tlScrollDown.current.kill();
        tlAboutYou.current.kill();
        tlDeepDive.current.kill();
        tlDesignArtifacts.current.kill();
        tlStrategyArtifacts.current.kill();
        tlContentFirst.current.kill();
      } catch (e) {
        // Code to run if an exception (error) occurs
      }
    };
  }, []);
  return (
    <React.Fragment>
      <SEO title="Our Design Process" article={true} />
      <Helmet>
        <title>Our Design Process</title>
      </Helmet>
      <Container>
        <Layout>
          <MainContent>
            <Branding getStandAloneTimelines={getStandAloneTimelines} />
            <Header title="Our Design" highlighted="Process">
              <ScrollCue
                getStandAloneTimelines={getStandAloneTimelines}
                scrollTo="about-you"
              />
            </Header>
            <AllAboutYou getStandAloneTimelines={getStandAloneTimelines} />
            <DeepDive getStandAloneTimelines={getStandAloneTimelines} />
            <StrategyArtifacts
              getStandAloneTimelines={getStandAloneTimelines}
            />
            <ContentFirst getStandAloneTimelines={getStandAloneTimelines} />
            <DesignArtifacts getStandAloneTimelines={getStandAloneTimelines} />
            <LetsBuild
              getStandAloneTimelines={getStandAloneTimelines}
              gridArea="8/2/9/4"
            />
            <Footer gridArea="9/2/10/4" />
          </MainContent>
          <Sidebar
            getStandAloneTimelines={getStandAloneTimelines}
            userInterface={"design"}
          />
        </Layout>
        <VisionMatrix
          matrixTimeline={getStandAloneTimelines}
          backgroundTimeline={getStandAloneTimelines}
        />
      </Container>
    </React.Fragment>
  );
};

export default DesignPage;
