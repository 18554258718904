import React, { useRef, useLayoutEffect } from "react";
import { useFrame } from "@react-three/fiber";

function VisionRandomMesh({ children, rotation }) {
  const dodecahedronMesh = useRef();
  const octahedronMesh = useRef();
  const torusMesh = useRef();
  const dodecahedron = useRef();
  const octahedron = useRef();
  const torus = useRef();

  useLayoutEffect(() => {
    dodecahedronMesh.current.position.x = 20;
    dodecahedronMesh.current.position.y = 10;
    octahedronMesh.current.position.x = 0;
    octahedronMesh.current.position.y = -10;
    torusMesh.current.position.x = -25;
    torusMesh.current.position.y = 10;
  }, []);

  useFrame(() => {
    dodecahedronMesh.current.rotation.y += rotation;
    dodecahedronMesh.current.rotation.z += rotation;

    octahedronMesh.current.rotation.x += rotation;
    octahedronMesh.current.rotation.z += rotation;

    torusMesh.current.rotation.y += rotation;
    torusMesh.current.rotation.z += rotation;
  });

  return (
    <React.Fragment>
      <mesh ref={dodecahedronMesh}>
        <dodecahedronGeometry
          ref={dodecahedron}
          args={[15, 0]}
        ></dodecahedronGeometry>
        {children}
      </mesh>
      <mesh ref={octahedronMesh}>
        <octahedronGeometry
          ref={octahedron}
          args={[15, 0]}
        ></octahedronGeometry>
        {children}
      </mesh>
      <mesh ref={torusMesh}>
        <torusGeometry ref={torus} args={[13, 4, 9, 31]}></torusGeometry>
        {children}
      </mesh>
    </React.Fragment>
  );
}

export default VisionRandomMesh;
