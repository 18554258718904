import React, { useRef, useState, useLayoutEffect } from "react";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import gsap from "gsap";

import {
  h2InnerStyles,
  technoOrange,
  centerAlignStyles,
  pStyles,
  termStyles,
} from "../styles/global.module.css";
import {
  aboutYouStyles,
  definitionStyles,
  dlAboutYouStyles,
  firstTermStyles,
  secondTermStyles,
  thirdTermStyles,
  fourthTermStyles,
  hoverBg,
  definition1Styles,
  definition2Styles,
  definition3Styles,
  definition4Styles,
} from "../styles/about-you.module.css";

function AllAboutYou({ getStandAloneTimelines }) {
  const breakpoints = useBreakpoint();
  const tlHover = useRef();
  const tlAboutYou = useRef();
  const [activeAboutYouTab, setActiveAboutYouTab] = useState("first-a-term");
  tlHover.current = gsap.timeline();
  tlAboutYou.current = gsap.timeline();

  useLayoutEffect(() => {
    let parentElement = document.getElementById("about-you");
    let children = parentElement.childNodes;
    tlAboutYou.current.from(children, {
      duration: 1,
      opacity: 0,
      stagger: { each: 0.2 },
    });
    getStandAloneTimelines(tlAboutYou.current, "tl-about-you");

    return function cleanupListener() {
      try {
        tlHover.current.kill();
        tlAboutYou.current.kill();
      } catch (e) {
        // Code to run if an exception (error) occurs
      }
    };
  }, []);

  useLayoutEffect(() => {
    switch (activeAboutYouTab) {
      case "first-a-term":
        gsap.set("#hover-bg", { gridArea: "term1" });
        gsap.to(".active-a-term", { duration: 0.1, fontWeight: "bold" });
        gsap.to(".active-a-tab", { duration: 0.1, opacity: 1 });
        break;
      case "first-a-term-sm":
        gsap.killTweensOf(".inactive-a-tab");
        gsap.set("#hover-bg", { gridArea: "term1" });
        gsap.to(".active-a-tab-sm", {
          duration: 0.1,
          opacity: 1,
          display: "block",
        });
        gsap.to("#about-you-dl", {
          duration: 0.5,
          gridTemplateRows:
            "4.2em min-content 4.2em 0.1em 4.2em 0.1em 4.2em 0.1em",
        });
        gsap.to(".active-a-term-sm", { duration: 0.1, fontWeight: "bold" });
        break;
      case "second-a-term":
        gsap.set("#hover-bg", { gridArea: "term2" });
        gsap.to(".active-a-term", { duration: 0.1, fontWeight: "bold" });
        gsap.to(".active-a-tab", { duration: 0.1, opacity: 1 });
        break;
      case "second-a-term-sm":
        gsap.killTweensOf(".inactive-a-tab");
        gsap.set("#hover-bg", { gridArea: "term2" });
        gsap.to(".active-a-tab-sm", {
          duration: 0.1,
          opacity: 1,
          display: "block",
          delay: 0.5,
        });
        gsap.to("#about-you-dl", {
          duration: 0.5,
          gridTemplateRows:
            "4.2em 0.1em 4.2em min-content 4.2em 0.1em 4.2em 0.1em",
        });
        gsap.to(".active-a-term-sm", { duration: 0.1, fontWeight: "bold" });
        break;
      case "third-a-term":
        gsap.set("#hover-bg", { gridArea: "term3" });
        gsap.to(".active-a-term", { duration: 0.1, fontWeight: "bold" });
        gsap.to(".active-a-tab", { duration: 0.1, opacity: 1 });
        break;
      case "third-a-term-sm":
        gsap.killTweensOf(".inactive-a-tab");
        gsap.set("#hover-bg", { gridArea: "term3" });
        gsap.to(".active-a-tab-sm", {
          duration: 0.1,
          opacity: 1,
          display: "block",
          delay: 0.5,
        });
        gsap.to("#about-you-dl", {
          duration: 0.5,
          gridTemplateRows:
            "4.2em 0.1em 4.2em 0.1em 4.2em min-content 4.2em 0.1em",
        });
        gsap.to(".active-a-term-sm", { duration: 0.1, fontWeight: "bold" });
        break;
      case "fourth-a-term":
        gsap.set("#hover-bg", { gridArea: "term4" });
        gsap.to(".active-a-term", { duration: 0.1, fontWeight: "bold" });
        gsap.to(".active-a-tab", { duration: 0.1, opacity: 1 });
        break;
      case "fourth-a-term-sm":
        gsap.killTweensOf(".inactive-a-tab");
        gsap.set("#hover-bg", { gridArea: "term4" });
        gsap.to(".active-a-tab-sm", {
          duration: 0.1,
          opacity: 1,
          display: "block",
          delay: 0.5,
        });
        gsap.to("#about-you-dl", {
          duration: 0.5,
          gridTemplateRows:
            "4.2em 0.1em 4.2em 0.1em 4.2em 0.1em 4.2em min-content",
        });
        gsap.to(".active-a-term-sm", { duration: 0.1, fontWeight: "bold" });
        break;
      default:
        gsap.set("#hover-bg", { gridArea: "term1" });
        break;
    }

    gsap.to(".inactive-a-term", { duration: 0.1, fontWeight: "100" });
    gsap.to(".inactive-a-tab", { duration: 0.1, opacity: 0 });

    tlHover.current
      .from("#rect1-bg", { duration: 0.5, translateX: -200 })
      .from("#rect2-bg", { duration: 0.5, translateX: 200 }, "-=0.5")
      .from("#rect3-bg", { duration: 0.5, translateX: 200 }, "-=0.5");
  }, [activeAboutYouTab]);
  return (
    <section id="about-you" className={`section ${aboutYouStyles}`}>
      <h2 className={`${h2InnerStyles} ${centerAlignStyles}`}>
        Understanding Your <span className={technoOrange}>Business</span>
      </h2>
      <dl id="about-you-dl" className={dlAboutYouStyles}>
        <svg
          id="hover-bg"
          className={hoverBg}
          width="750"
          height="75"
          viewBox="0 0 450 75"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect id="main-rect-bg" width="750" height="75" fill="white" />
          <rect id="rect1-bg" x="106" width="16" height="75" fill="#101010" />
          <rect id="rect2-bg" x="60" width="16" height="75" fill="#101010" />
          <rect id="rect3-bg" x="25" width="16" height="75" fill="#101010" />
        </svg>
        <dt
          onClick={
            breakpoints.sm
              ? () => setActiveAboutYouTab("first-a-term-sm")
              : () => setActiveAboutYouTab("first-a-term")
          }
          className={`${firstTermStyles} ${termStyles} ${technoOrange} ${
            activeAboutYouTab === "first-a-term"
              ? "active-a-term"
              : activeAboutYouTab === "first-a-term-sm"
              ? "active-a-term-sm"
              : "inactive-a-term"
          }`}
        >
          Business History
        </dt>
        <dd
          className={`${definitionStyles} ${definition1Styles} ${
            activeAboutYouTab === "first-a-term"
              ? "active-a-tab"
              : activeAboutYouTab === "first-a-term-sm"
              ? "active-a-tab-sm"
              : "inactive-a-tab"
          }`}
        >
          <p className={`${pStyles}`}>
            Building anything with "on the surface" knowledge will get you an
            end product that's just ok. We aim for exceptional. Your return on
            investment is important to us, so we delve into understanding your
            business and its challenges at a much deeper level. How did it
            start? Why did it start? What are the pain points? What keeps your
            management team up at night? What has worked? What hasn't worked?
            Every detail that helps us understand your perspective goes a long
            way to help us facilitate your digital success.
          </p>
        </dd>
        <dt
          onClick={
            breakpoints.sm
              ? () => setActiveAboutYouTab("second-a-term-sm")
              : () => setActiveAboutYouTab("second-a-term")
          }
          className={`${secondTermStyles} ${termStyles} ${technoOrange} ${
            activeAboutYouTab === "second-a-term"
              ? "active-a-term"
              : activeAboutYouTab === "second-a-term-sm"
              ? "active-a-term-sm"
              : "inactive-a-term"
          }`}
        >
          Digital Vision
        </dt>
        <dd
          className={`${definitionStyles} ${definition2Styles} ${
            activeAboutYouTab === "second-a-term"
              ? "active-a-tab"
              : activeAboutYouTab === "second-a-term-sm"
              ? "active-a-tab-sm"
              : "inactive-a-tab"
          }`}
        >
          <p className={`${pStyles}`}>
            When you think of your ideal digital presence, something comes to
            mind. We want to understand what that is. Whether it's a strategy
            you've seen another business implement or just your version of
            online success, understanding what your benchmarks are gives us an
            initial direction. Don't worry if you've never thought about this,
            we'll work it out together. Our expertise in the digital landscape
            and our research team converge to provide solutions that are truly
            unique.
          </p>
        </dd>
        <dt
          onClick={
            breakpoints.sm
              ? () => setActiveAboutYouTab("third-a-term-sm")
              : () => setActiveAboutYouTab("third-a-term")
          }
          className={`${thirdTermStyles} ${termStyles} ${technoOrange} ${
            activeAboutYouTab === "third-a-term"
              ? "active-a-term"
              : activeAboutYouTab === "third-a-term-sm"
              ? "active-a-term-sm"
              : "inactive-a-term"
          }`}
        >
          Ideal Audience
        </dt>
        <dd
          className={`${definitionStyles} ${definition3Styles} ${
            activeAboutYouTab === "third-a-term"
              ? "active-a-tab"
              : activeAboutYouTab === "third-a-term-sm"
              ? "active-a-tab-sm"
              : "inactive-a-tab"
          }`}
        >
          <p className={`${pStyles}`}>
            This is what it's all about, your audience. Who are they? Are there
            multiple audiences you provide your services or products to? A great
            deal of this knowledge may be tacit for you. You know it because
            you've been doing it for a long time, and this is extremely helpful
            for us. What we'll add to that is some explicit knowledge from data,
            as we test hypotheses and run discovery campaigns. This data then
            informs your decisions for capturing even more marketshare.
          </p>
        </dd>
        <dt
          onClick={
            breakpoints.sm
              ? () => setActiveAboutYouTab("fourth-a-term-sm")
              : () => setActiveAboutYouTab("fourth-a-term")
          }
          className={`${fourthTermStyles} ${termStyles} ${technoOrange} ${
            activeAboutYouTab === "fourth-a-term"
              ? "active-a-term"
              : activeAboutYouTab === "fourth-a-term-sm"
              ? "active-a-term-sm"
              : "inactive-a-term"
          }`}
        >
          Sales
        </dt>
        <dd
          className={`${definitionStyles} ${definition4Styles} ${
            activeAboutYouTab === "fourth-a-term"
              ? "active-a-tab"
              : activeAboutYouTab === "fourth-a-term-sm"
              ? "active-a-tab-sm"
              : "inactive-a-tab"
          }`}
        >
          <p className={`${pStyles}`}>
            And here we are! The second thing it's all about, the sales (unless
            you're a Non-Profit). What are your sales channel(s) and how have
            they been performing? What are your plans for improving them?
            Gathering this information is critical to project success as these
            eventually feed into project benchmarks. We ensure that there is
            always a direct correlation between our efforts and your bottom
            line. Focusing on the pieces that impact sales upfront helps build
            momentum for progressing through the entire roadmap. Small wins
            inevitably lead to big wins.
          </p>
        </dd>
      </dl>
    </section>
  );
}

export default AllAboutYou;
